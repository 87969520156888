



































































import Avatar from '@/modules/community/common/components/avatar.vue'
import { Observer } from 'mobx-vue'
import { Component, Ref, Vue } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'

import { recruitmentPostController } from '@/modules/recruitment/dialogs/recruitment-post-controller'
import { when } from 'mobx'
import { debounce } from 'lodash'

@Observer
@Component({
  components: {
    Avatar,
    'confirm-discard-post-dialog': () => import('@/modules/common/dialogs/post/confirm-discard-post-dialog.vue'),
    'rct-post-form': () => import('@/modules/recruitment/dialogs/forms/rct-post-form.vue'),
  },
})
export default class extends Vue {
  @Ref('form') form: any
  controller = recruitmentPostController
  createLoading = false
  walletStore = walletStore

  // mounted() {
  //   this.addScrollListener()
  // }

  // async addScrollListener() {
  //   await when(() => this.controller.createPostDialog)
  //   const scrollWrapper = document.getElementsByClassName('post-content-wrapper')[0]
  //   scrollWrapper?.addEventListener(
  //     'scroll',
  //     debounce(() => {
  //       // this.reviewCategories?.blur()
  //     }, 100)
  //   )
  // }

  async createPost() {
    if (!this.form.validate()) return
    this.createLoading = true
    await this.controller.createPost()
    this.createLoading = false
  }

  closeDialog() {
    if (this.controller.createPostLoading) return
    this.controller.changeOpenConfirmDiscardPostDialog(true)
  }
}
